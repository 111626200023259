<template>
    <div class="white-bg">
		<div class="main-wrap">
			<div class="container">

				<!-- Blog content -->
				<div class="col-md-16 masonry-blog" id="portfolio">
					<div class="row">
						<div id="folio" class="isotope">
							<div class="portfolio-inner">
								<div class="folio-item col-md-4 isotope-item" v-for="(actualite,index) in actualites" :key="index">
									<article>
										<img :src="JNEPCI+actualite.photo" class="img-responsive" alt=""/>
										<div class="post-info">
											<span class="post-meta">{{actualite.date}}  <a href="#"></a></span>
											<h4><router-link :to="'/actualite-details/'+actualite.id">{{ actualite.titre.substring(0,23)+".."}}</router-link></h4>
											<p v-html="actualite.description.substring(3,220)+'...'"></p>
											<div class="line"></div>
											<router-link class="hb-more" :to="'/actualite-details/'+actualite.id">voir plus</router-link>
											<ul class="hb-social">
												<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
												<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
												<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
											</ul>
										</div>
									</article>
								</div>
								
							</div>
						</div>
					</div>
					<div class="page-meta">
						<ul class="page-nav">
							<li>Page 1 of 5 
                            </li>
							<li class="active"><a href="#">1</a>
                            </li>
							<li><a href="#">2</a>
                            </li>
							<li><a href="#">3</a>
                            </li>
							<li><a href="#">4</a>
                            </li>
							<li><a href="#">5</a>
                            </li>
						</ul>
						<ul class="page-nav-links">
							<li><a href="#">&laquo; Previous page</a></li>
							<li><a href="#">Next page &raquo;</a></li>
						</ul>
					</div>
				</div>
				<!-- Blog content -->
			</div>
		</div>

		<div class="space0"></div>


	</div>
</template>
<script>
import axios from 'axios';
import { JNEPCI_URL,JNEPCI } from '@/config'
// import truncate from 'html-truncate';
var moment = require('moment');
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    // components: {
    //         Loading
    //     },
    data: function () {
        return {
            actualites:[],
            bureau:[],
            JNEPCI:JNEPCI,
            isLoading:false
        }

    },
    mounted:function(){
        this.getDepartement()
    },
    methods:{
            getDepartement: function() {
                // var truncate = require('html-truncate');
                moment.locale('fr');
                let app = this;
                app.isLoading = true;
                axios.get(JNEPCI_URL+'actualites')
                .then((response) => 
                {
                  console.log(response)
                  response.data.forEach(element => {
                      element.date = moment(element.created_at, "YYYYMMDD").fromNow();
                  });
                   
                    app.actualites = response.data
                    app.isLoading = false;
                })
                .catch((   ) => {
                    app.isLoading = false;
                });
            },
    }
}
</script>