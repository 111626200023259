<template>
    <div>
        <footer>
            <div class="footer1">
                <div class="container">
                    <div class="col-md-8">
                        <img src="/logo-jnepci.png" width="20%" class="img-responsive" alt="" />
                    </div>
                    <div class="col-md-8">
                        <h6>Connecte-toi avec nous</h6>
                        <ul class="hb-social">
                            <li class="hb-fb"><a href="https://web.facebook.com/jnepci/"><i class="fa fa-facebook"></i></a></li>
                            <li class="hb-tw"><a href="https://twitter.com/JNEPCI"><i class="fa fa-twitter"></i></a></li>
                            <li class="hb-plus"><a href="https://www.youtube.com/channel/UCapvFRK44oQ4CCKani_y9Cw"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer2">
                <div class="container">
                    <div class="col-md-4 footer-widget">
                        <h5>Nos contacts</h5>
                        <p class="mb-0">Email: jnepci@jnepci.org <br>
                        (+225) 05 44 55 45 45  <br>
                        (+225) 07 09 96 23 76 <br>
                        (+225) 01 01 80 76 77</p>
                    </div>
                    <div class="col-md-3 footer-widget fw2">
                        <h5>Jour et heure de culte</h5>
                        <p>Service du dimanche matin<br> 08h00
                        </p>
                        <p>Service du mardi soir<br> 18h00
                        </p>
                        <p>Service du jeudi soir<br> 18h00
                        </p>
                    </div>
                    <div class="col-md-9 footer-widget last">
                        <div class="footer-quote">Toutes les Ecritures sont inspirées de Dieu et utiles pour enseigner, pour convaincre, pour corriger, pour instruire dans la justice 
                        </div>
                        <p>
                            Copyright &copy;  Tout droit reservé<i class="icon-heart text-warning" aria-hidden="true">|</i> Make by <a href="https://web.facebook.com/Anani.Cedric" target="_blank" >Anani Kouadio Cedric</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    
}
</script>