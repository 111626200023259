<template>
    <div class="white-bg">
		<div class="main-wrap">
			<div class="container">
				<div class="col-md-12">
					<div class="events-wrap events-wrap-big">
						<div class="row">
							<div class="col-md-16">
								<img :src="JNEPCI+teach.photo" class="img-responsive" alt=""/>
								<div class="row">
									<div class="col-md-11">
										<div class="space20"></div>
										<h4 v-if="teach.auteur">{{ teach.titre}}<span>{{ teach.titre}}</span></h4>
                                        <h4 v-else>JNEPCI<span>{{ teach.titre}}</span></h4>
									</div>
									<div class="col-md-5">
										<ul class="hb-social">
											<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
											<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
											<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
										</ul>
									</div>
								</div>
								<ul class="events-meta">
									<li><b>Date:</b> {{ teach.created_at}}</li>
								</ul>
								<div class="space30"></div>
								<p v-html="teach.description"> </p>
							</div>
						</div>
					</div>
				</div>
				<aside class="col-md-4">
					<div class="side-widget about">
						<h5>Questions?</h5>
						<p>Si vous avez des questions, n'hésitez pas à nous contacter   (<a href="#">epci.jn@gmail.com</a>), pour tout ce qui concerne cet événement </p>
						<p>Pour toute autre question, utilisez notre . <a href="/contact">formulaire de contact général</a></p>
					</div>
					<div class="space80"></div>
					<!-- <div class="side-widget about">
						<h5>Where will be Event?</h5>
						<img src="demo/map.jpg" class="img-responsive" alt=""/>
						<a href="#" class="hb-more">View Full Map</a>
					</div> -->
				</aside>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import { JNEPCI_URL,JNEPCI } from '@/config'
export default {

    data: function () {
        return {
            teach:null,
            JNEPCI:JNEPCI,
            isLoading:false
        }

    },
    mounted:function(){
        this.getEnseignement()
    },
    methods:{
            getEnseignement: function() {
                let app = this;
                app.isLoading = true;
                axios.get(JNEPCI_URL+'actualites/'+this.$route.params.id)
                .then((response) => {
                     console.log(response)
                    app.teach = response.data
                    this.isLoading = false;
                })
                .catch((   ) => {
                    app.isLoading = false;
                });
            },
    }
}
</script>